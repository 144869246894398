@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	/* Colours */
	--color-green: #a4d037;
	--color-yellow: #fecd35;
	--color-red: #f05230;
	--color-darkgrey: #15171a;
	--color-midgrey: #738a94;
	--color-lightgrey: #f1f1f1;
	--color-secondary-text: #979797;
	--color-border: #e1e1e1;
	--color-wash: #e5eff5;
	--color-darkmode: #151719;

	/*
  An accent color is also set by Ghost itself in
  Ghost Admin > Settings > Brand

  --ghost-accent-color: {value};

  You can use this variable throughout your styles
   */

	/* Fonts */
	--font-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	--font-serif: Georgia, Times, serif;
	--font-mono: Menlo, Courier, monospace;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

.imageContainer {
	width: 100%;
}

.imageContainer > div {
	position: unset !important;
}

.imageContainer .image {
	object-fit: contain;
	width: 100% !important;
	position: relative !important;
	height: unset !important;
}

.text-rainbow {
	width: fit-content;
	color: transparent;
	font-weight: 700 !important;
	font-size: 20px !important;
	text-shadow: none !important;
	background: linear-gradient(270deg, #479cff, #3c49ff);
	-webkit-background-clip: text;
	background-clip: text;
}
